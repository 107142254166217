<template>
    <div class="min-vh-100 d-flex align-items-center justify-content-center">
        <div class="d-block bg-white justify-content-center align-items-center m-3 p-3">
            <h4>
                <div>Please confirm your identity</div>
            </h4>
            <div>
                <kyc-form />
            </div>
        </div>
    </div>
</template>
<script>
import KycForm from "@/components/UserPages/KycForm";

export default {
    components: {
        KycForm,
    },
    data: () => ({
        slickOptions6: {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            initialSlide: 0,
            autoplay: true,
            adaptiveHeight: true
        },

        slide: 0,
        sliding: null,
        refferal: ''
    }),

    mounted() {
        if (this.$route.query.inviteId) {
            this.refferal = this.$route.query.inviteId
        }
    },
    methods: {
        handleClick(newTab) {
            this.currentTab = newTab;
        },
        next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        },

        reInit() {
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },

        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        }
    }
};
</script>
