<template>
    <b-form>
        <b-row>
            <b-col md="6" >
                <b-form-group
                    label="First name"
                >
                    <b-form-input id="firstName" type="text" name="firstName" placeholder="First name" v-model="form.firstName" required />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Last name"
                >
                    <b-form-input id="lastName" type="text" name="lastName" placeholder="Last name" v-model="form.lastName" required />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Gender"
                >
                    <b-form-select
                        id="inline-form-custom-select-pref"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        :options="[{ text: 'Choose gender...', value: null }, {text: 'Male', value: 0}, {text: 'Female', value: 1}]"
                        :value="null"
                        v-model="form.gender"
                        :state="form.gender !== null"
                        required
                    ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Address"
                >
                    <b-form-input id="address1" type="text" name="address1" placeholder="Street address" v-model="form.streetAddress" required />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Address 2"
                >
                    <b-form-input id="address2" type="text" name="address2" placeholder="Street address" v-model="form.addressLine2"  />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="City"
                >
                    <b-form-input id="city" type="text" name="city" placeholder="City" v-model="form.city" required />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Citizenship"
                >
                    <b-form-input id="region" type="text" name="region" placeholder="Citizenship" v-model="form.region"  />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Country of residence"
                >
                    <country-select v-model="form.country" :country="form.country" class="form-control" />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Date of Birth"
                >
                    <date-picker v-model="form.dateOfBirth" lang="en" :disabled-date="disabledDate" placeholder="Select Date" required></date-picker>
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Place of birth"
                >
                    <b-form-input id="pob" type="text" name="pob" placeholder="Place of birth" v-model="form.placeOfBirth" required />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Document type"
                >
                    <b-form-select
                        id="inline-form-custom-select-pref"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        :options="[{ text: 'Choose document type...', value: null }, {text: 'Passport', value: 2}, {text: 'National ID Card', value: 1}]"
                        :value="null"
                        v-model="form.documentType"
                        :state="!!form.documentType"
                        required
                    ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Document number"
                >
                    <b-form-input id="documentNo" type="text" name="documentNo" placeholder="Document number" v-model="form.documentNumber" required />
                </b-form-group>
            </b-col>
            <b-col md="6" v-if="form.documentType === 1">
                <b-form-group
                    label="Proof of identity (front and back side):"
                >
                    <b-form-file
                        v-model="frontside"
                        :state="Boolean(frontside)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        @change="submitFrontside"
                        accept=".jpg, .png, .pdf"
                    ></b-form-file>
                    <div class="my-2" />
                    <b-form-file
                        v-model="backside"
                        :state="Boolean(backside)"
                        placeholder="Choose a fileor drop it here..."
                        drop-placeholder="Drop file here..."
                        @change="submitBackside"
                        accept=".jpg, .png, .pdf"
                    ></b-form-file>
                </b-form-group>
            </b-col>
            <b-col md="6" v-if="form.documentType === 2">
                <b-form-group
                    label="Proof of identity:"
                >
                    <b-form-file
                        v-model="frontside"
                        :state="Boolean(frontside)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        @change="submitFrontside"
                        accept=".jpg, .png, .pdf"
                    ></b-form-file>
                </b-form-group>
            </b-col>
            <b-col md="6" v-if="!form.documentType" />
            <b-col md="6">
                <b-form-group
                    label="Please upload a copy of electricity, water or gas bill or bank statement with your name and current address. The document must not be older than three months."
                >
                    <b-form-file
                        v-model="proof"
                        :state="Boolean(proof)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        @change="submitProof"
                        accept=".jpg, .png, .pdf"
                    ></b-form-file>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12" v-if="error">
                <b-form-group>
                    <div class="alert alert-danger" role="alert">
                        {{error}}
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="6" >
                <div class="ml-auto">
                    <v-btn color="error" class="text-unset" @click="back">Return</v-btn>
                </div>
            </b-col>
            <b-col cols="6" >
                <div class="ml-auto">
                    <v-btn color="primary" class="text-unset" @click="submitKyc">Submit</v-btn>
                </div>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>

import DatePicker from "vue2-datepicker";

import gql from "graphql-tag";
export default {
    name: "KycForm",
    components: {
        DatePicker,
    },
    data() {
        return {
            form: {
                firstName: "",
                lastName: "",
                gender: 0,
                streetAddress: "",
                addressLine2: "",
                city: "",
                region: "",
                country: "",
                dateOfBirth: null,
                placeOfBirth: "",
                documentType: 0,
                documentNumber: "",
                frontside: "",
                backside: "",
                proof: ""
            },
            frontside: "",
            backside: "",
            proof: "",
            error: null
        }
    },
    mounted() {
        this.form.firstName = this.$store.getters["user/firstName"]
        this.form.lastName = this.$store.getters["user/lastName"]
    },
    computed: {
        inputValid() {
            if (this.form.documentType === 1 && this.form.backside === "") return false
            return this.form.firstName.length > 1 && this.form.lastName.length > 1 &&
                this.form.proof.length > 10 && this.form.frontside.length > 10 &&
                this.form.dateOfBirth && this.form.documentNumber.length > 5 && this.form.streetAddress.length > 1 &&
                this.form.country.length > 0 && this.form.placeOfBirth.length > 1 && this.form.city.length > 1
        },
    },
    methods: {
        back() {
            this.$router.push("/profile")
        },
        submitProof(e) {

            let formData = new FormData();
            formData.append('file', e.target.files[0]);
            this.axios.post(process.env.VUE_APP_HTTP + 'v1/submit/image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then((response) => this.form.proof = response.data.filePath)

        },
        submitFrontside(e) {

            let formData = new FormData();
            formData.append('file', e.target.files[0]);
            this.axios.post(process.env.VUE_APP_HTTP + 'v1/submit/image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then((response) => this.form.frontside = response.data.filePath)

        },
        submitBackside(e) {

            let formData = new FormData();
            formData.append('file', e.target.files[0]);
            this.axios.post(process.env.VUE_APP_HTTP + 'v1/submit/image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then((response) => this.form.backside = response.data.filePath)

        },
        submitKyc() {
            this.error = null
            if (!this.form.firstName) this.error = "Missing first name"
            if (!this.form.lastName) this.error = "Missing last name"
            if (!this.form.streetAddress) this.error = "Missing street address"
            if (!this.form.city) this.error = "Missing city"
            if (!this.form.region) this.error = "Missing citizenship"
            if (!this.form.dateOfBirth) this.error = "Missing date of birth"
            if (!this.form.placeOfBirth) this.error = "Missing place of birth"
            if (!this.form.documentNumber) this.error = "Missing document number"

            if (this.error) return

            this.$apollo.mutate({
                mutation: gql`mutation ($kyc:KycInput!){submitKyc(kyc:$kyc)}`,
                variables: {
                    kyc: this.form
                }
            }).then((res)=> {
                if (res.data.submitKyc.error) {
                    this.error = res.data.submitKyc.error
                }
                else {
                    this.$router.push("profile")
                }
            }).error((err) => {
                this.error = err
            })
        },
        disabledDate(val) {
            return val > Date.now()
        }
    }
}
</script>

<style scoped>
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding-left: 0 !important;
    display: block;
}
</style>